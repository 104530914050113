import JavaScriptObfuscator from "javascript-obfuscator";

export let obfuscationResult = (formObj) => {
  let status = "";
  console.log(formObj.previewmode);
  // Yes I'm intentionally evaluating the STRING TRUE. Don't change it unless you want to figure work around what the html element returns.
  if (formObj.previewmode == "true") {
    status = `<!-- Preview Mode Is Active | add "?${formObj.gaevent}" to url to see. --><link rel="stylesheet" href="https://s3.amazonaws.com/xtemp19.lb/test/lb.css"><script>`;
  } else {
    status = `<!-- THIS LB CODE IS LIVE. If unseen, delete cookie named "${formObj.gaevent}" --><link rel="stylesheet" href="https://s3.amazonaws.com/xtemp19.lb/test/lb.css"><script>`;
  }
  
    // don't put comments inside of the tempString template
  let tempString = `
    (function() {
        var cookieName = '${formObj.gaevent}';
        var previewMode = ${formObj.previewmode};

        function createCookie(name, value, hours) {
            var date, expires;
            if (hours) {
                date = new Date();
                date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
                expires = "; expires=" + date.toGMTString();
            }
            else {
                expires = "";
            }
            document.cookie = name + "=" + value + expires + "; path=/";
        }
        var testingUrl = new RegExp(cookieName);
        if (previewMode === true && window.location.href.match(testingUrl)) {
            showLightBox();
        }

        if (previewMode == false) {
            if (document.cookie.indexOf(cookieName) !== -1) {
                return;
            }
            createCookie(cookieName, 'true', ${formObj.frequency});
            showLightBox();
        }
        
        document.addEventListener('keyup', function(e) {
            if(e.which == 27){
                document.querySelector('.rkd-000-lightbox-overlay').style.display = 'none';
                document.querySelector('.rkd-000-popup').style.display = 'none';
            }
        });

        function showLightBox() {
            var desktopImage = "${formObj.imagesrc}",
                urlLink ="${formObj.linkurl}";
            var desktopId = "desktopLearnMore",
                mobileId = "mobileLearnMore",
                descriptionText ="${formObj.altText}";
        
            var popup = '<div class="rkd-000-popup" id="${
              formObj.mobileHidden === "true" ? "mobileHidden" : "mobileShown"
            }"><div class="rkd-000-content"><a describedby="' +
            desktopId +
            '"  class="rkd-000-donate-now" href="' + urlLink + '" ><img src="' + desktopImage + '" class="rkd-000-desk-content" alt="' +
            descriptionText +
            '"/></a></div><div class="rkd-000-closing-footer"><a href="#" class="rkd-000-close-button">&times;</a></div></div><div class="rkd-000-lightbox-overlay" id="${
                formObj.mobileHidden === "true" ? "mobileHidden" : "mobileShown"
              }"></div>';


            var i = document.createElement('DIV');
            i.id = 'lightboxAppend';
            
            document.getElementsByTagName("body")[0].prepend(i);

            i.innerHTML = popup;

            var overlay = document.querySelector('.rkd-000-lightbox-overlay');
            var popupLightbox = document.querySelector('.rkd-000-popup');

            overlay.style.display = 'block';
            popupLightbox.style.display = 'block';

            document.querySelector('.rkd-000-popup .rkd-000-close-button').addEventListener('click', function(e) {
                e.preventDefault();
                overlay.style.display = 'none';
                popupLightbox.style.display = 'none';
            });

            overlay.addEventListener('click', function() {
                overlay.style.display = 'none';
                popupLightbox.style.display = 'none';
            });

            if (document.querySelector('.rkd-000-popup .rkd-000-donate-now')) {
            
                var allDonateButtons = document.body.querySelectorAll('.rkd-000-popup .rkd-000-donate-now');
                
                for (var x = 0; x < allDonateButtons.length; x++) {
                    allDonateButtons[x].addEventListener('click', function() {
                        window.dataLayer.push({
                            event: "dynamicEvent",
                            dynamicCategory: 'lightbox',
                            dynamicAction: 'click',
                            dynamicLabel: cookieName
                        });
                    });
                }
            }
            
            window.dataLayer.push({
                event: "dynamicEvent",
                dynamicCategory: 'lightbox',
                dynamicAction: 'render',
                dynamicLabel: cookieName
            }); 
        }
    })();`;

  // you can uncomment this return to see the un-obfuscated code returned. don't add comments into the template string.
//   it will make troubleshooting harder and add unnecessary weight to the production code on the client sites.

//    return String(`${status} ${tempString}`);

  return String(
    status +
      JavaScriptObfuscator.obfuscate(tempString, {
        compact: false,
        stringArray: true,
        stringArrayThreshold: 1,
        stringArrayEncoding: ["base64"],
      })
  );
};
