import React from "react";
import ReactDOM from "react-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./styles.css";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { obfuscationResult } from "./obfuscate";
import Tooltip from "@material-ui/core/Tooltip";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LightboxPress = () => {
  const [open, setOpen] = React.useState(false);
  const [formState, setFormState] = React.useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopy = () => {
    function copyDivToClipboard() {
      var range = document.createRange();
      range.selectNode(document.getElementById("the-code"));
      window.getSelection().removeAllRanges(); // clear current selection
      window.getSelection().addRange(range); // to select text
      document.execCommand("copy");
      window.getSelection().removeAllRanges(); // to deselect
    }
    copyDivToClipboard();
  };

  return (
    <>
      <a href="https://rkdgroup.com">
        <img
          src="../rkd-logo.png"
          alt="rkdgroup logo"
          style={{ width: "40%" }}
        ></img>
      </a>
      <h1 style={{ marginBottom: "3.4rem", marginTop: ".5rem" }}>
        Lightbox Maker
      </h1>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Lightbox Script"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <code id="the-code">
              {obfuscationResult(formState)}
              {`</script>`}
            </code>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCopy} color="primary">
            <strong>Copy Code!</strong>
          </Button>
          <Button onClick={handleClose} color="primary">
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
      <Formik
        initialValues={{
          gaevent: "",
          imagesrc: "",
          linkurl: "",
          frequency: 24,
          previewmode: "true",
          mobileHidden: "true",
          altText: "",
        }}
        validationSchema={Yup.object({
          gaevent: Yup.string()
            .min(6, "The event name should be at least 6 characters. It should be fairly unique.")
            .max(45, "The event name should be at most 45 characters.")
            .required("Required"),
          imagesrc: Yup.string().url("invalid url")
            .matches(/.ttps./, "your image url needs to start with https://").required("Required"),
          linkurl: Yup.string().url("invalid url")
          .matches(/.ttps./, "your link url needs to start with https://").required("Required"),
          frequency: Yup.number().required("Required"),
          altText: Yup.string()
            .matches(/^[a-zA-Z0-9\s.,\-'&()$:!=%?+]*$/, "Invalid character")
            .notRequired(),
        })}
        onSubmit={(values) => {
          setTimeout(() => {
            handleClickOpen();
            setFormState(values);
          }, 100);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <Tooltip
              title={
                "This campaign name will decide what the Google Analytics Events look like among other things."
              }
              placement="top-start"
            >
              <label htmlFor="gaevent">Campaign Name</label>
            </Tooltip>
            <Field id="gaevent" name="gaevent" type="text" />
            <ErrorMessage name="gaevent">{msg => <span style={{color:"#AA1D45"}}>{msg}</span>}</ErrorMessage>
            <Tooltip
              title={
                "This is the url of the image you would like to show in the lightbox. It should start with https://"
              }
              placement="top-start"
            >
              <label htmlFor="imagesrc">Image Src URL</label>
            </Tooltip>
            <Field id="imagesrc" name="imagesrc" type="text" />
            <ErrorMessage name="imagesrc">{msg => <span style={{color:"#AA1D45"}}>{msg}</span>}</ErrorMessage>
            <Tooltip
              title={
                "This is the url of the link, that the lightbox sends users to upon click. It should start with https://"
              }
              placement="top-start"
            >
              <label htmlFor="linkurl">Link URL</label>
            </Tooltip>
            <Field id="linkurl" name="linkurl" type="text" />
            <ErrorMessage name="linkurl">{msg => <span style={{color:"#AA1D45"}}>{msg}</span>}</ErrorMessage>
            <Tooltip
              title={
                "The Cookie Snooze Time is how long you want the lightbox to wait before it shows itself again to an individual user measured in hours. This is set by default so when the same user returns after 24 hours they can see the lightbox again."
              }
              placement="top-start"
            >
              <label htmlFor="frequency">Cookie Snooze Time (hours)</label>
            </Tooltip>
            <Field id="frequency" name="frequency" type="number" />
            <ErrorMessage name="frequency">{msg => <span style={{color:"#AA1D45"}}>{msg}</span>}</ErrorMessage>
            <Tooltip
              title={
                "This is the alternative text that will be read by the screen readers"
              }
              placement="top-start"
            >
              <label htmlFor="altText">
                Alternative Text (<a style={{color:"#036"}} href="https://www.w3.org/WAI/tutorials/images/decision-tree/" target="_blank" rel="noopener noreferrer">Image alt</a>)
              </label>
            </Tooltip>
            <Field id="altText" name="altText" type="text" />
            <ErrorMessage name="altText">{msg => <span style={{color:"#AA1D45"}}>{msg}</span>}</ErrorMessage>
            <Tooltip
              title={
                "When Preview Mode is on, one can preview the lightbox by putting the campaign name into a url parameter. When it is off the lightbox will deploy live!"
              }
              placement="top-start"
            >
              <div
                id="previewmode"
                style={{ fontWeight: "bold", marginTop: "10px" }}
              >
                Preview Mode
              </div>
            </Tooltip>

            <div
              role="group"
              aria-labelledby="previewmode"
              style={{ display: "flex", flexFlow: "row" }}
            >
              <label style={{ marginRight: "20px" }}>
                <Field type="radio" name="previewmode" value="true" />
                On
              </label>
              <label>
                <Field type="radio" name="previewmode" value="false" />
                Off
              </label>
            </div>

            <Tooltip
              title={
                "If hidden is selected the lightbox will not be shown on screens below 600px in width."
              }
              placement="top-start"
            >
              <div
                id="mobileHidden"
                style={{ fontWeight: "bold", marginTop: "10px" }}
              >
                Show on mobile?
              </div>
            </Tooltip>
            <div
              role="group"
              aria-labelledby="mobileHidden"
              style={{ display: "flex", flexFlow: "row" }}
            >
              <label style={{ marginRight: "20px" }}>
                <Field type="radio" name="mobileHidden" value="true" />
                Hidden
              </label>
              <label>
                <Field type="radio" name="mobileHidden" value="false" />
                Shown
              </label>
            </div>

            <br />
            <button type="submit">Submit</button>
          </Form>
        )}
      </Formik>
      {formState.imagesrc && (
        <div
          className="dark-container"
          style={{
            background: "rgba(0,0,0,.9)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 450,
          }}
        >
          <img alt="logo" src={formState.imagesrc} />
        </div>
      )}
    </>
  );
};

function App() {
  return <LightboxPress />;
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
